import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput, FieldRadioButton } from '../../../components';

import css from './SignupForm.module.css';
import SignupFormStep from './SignupFormStep.js'
import FieldPhoneInput from '../../../components/FieldPhoneInput/FieldPhoneInput.js';
import BackArrow from '../../InvoicePage/img/back_arrow.png';

const SignupFormComponent = props => {
  const [step, setStep] = useState(1);
  const [step1Values, setStep1Values] = useState({});
  // const [verifiedFlags, setVerifiedFlags] = useState({
  //   email: false,
  //   phoneNumber: false,
  // });

  const handleSubmitStep1 = values => {
    // Store values from step 1 and move to step 2
    setStep1Values(values);
    setStep(2);
  };

  const handleSubmitValues = values => {
    const finalValues = { ...values };
    console.log('Final form values:', finalValues);
    props.onSubmit(finalValues);
  };

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      // onSubmit={handleSubmitValues}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          termsAndConditions,
        } = fieldRenderProps;

        const handleUserTypeChange = (event) => {
          const userType = event.target.value;
          fieldRenderProps.form.change('user', userType);
        };
        
        const handleProceedClick = () => {
          const userType = fieldRenderProps?.values?.user;
          const hasEmail = fieldRenderProps?.values?.email;
 
          if (userType === 'company' && hasEmail) {
            setOpenCompanySignup(true);
            setOpenPersonSignup(false); 
            setOpenSignUp(false); 
          } else if (userType === 'person' && hasEmail) {
            setOpenPersonSignup(true);
            setOpenCompanySignup(false); 
            setOpenSignUp(false); 
          }
        };

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );
      
        const companyNameLabel = intl.formatMessage({
            id: 'ConfirmSignupForm.companyNameLabel',
          });

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const phoneNumberRequiredMessage = intl.formatMessage({
          id: 'SignupForm.phoneNumberRequired',
        });
        const phoneNumberNotValidMessage = intl.formatMessage(
          {
            id: 'SignupForm.phoneNumberNotValidMessage',
          },
          {
            maxLength: validators.PHONENUMBER_MAX_LENGTH,
          }
        );

        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        
        const cvrRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.cvrRequired',
          })
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );
        const phoneNumberMaxLength = validators.maxLength(
          phoneNumberNotValidMessage,
          validators.PHONENUMBER_MAX_LENGTH
        );
        const phoneNumberMinLength = validators.minLength(
          phoneNumberNotValidMessage,
          validators.PHONENUMBER_MIN_LENGTH
        );
        const validPhoneNumber = validators.validPhoneNumber(
          phoneNumberNotValidMessage,
        );
        const phoneNumberRequired = validators.requiredPhonenumber(phoneNumberRequiredMessage);
        const phoneNumberValidators = validators.composeValidators(
          phoneNumberRequired,
          phoneNumberMinLength,
          phoneNumberMaxLength,
          validPhoneNumber
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress || !fieldRenderProps.modified.userType;
        const proceedSubmitDisabled = !fieldRenderProps.modified.user && !fieldRenderProps.modified.email || invalid || submitInProgress ;
               
        const [openPersonSignup, setOpenPersonSignup] = useState(false);
        const [openCompanySignup, setOpenCompanySignup] = useState(false);
        const [openSignUp, setOpenSignUp] = useState(true);
        
        const handleBackLinkClick = ()=>{          
            setOpenCompanySignup(false);
            setOpenPersonSignup(false);
            setOpenSignUp(true);          
        }
        

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {openPersonSignup?
            <>
            <div>
              <div className={css.sendInvoiceHEader}>
                <button className={css.backButton} onClick={handleBackLinkClick}>
                  <img src={BackArrow} alt="" width={30} />{' '} Back
                </button>
              </div> 
       
  
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.firstNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div> <br/>              

                <FieldPhoneInput
                  className={css.phone}
                  name="phoneNumber"
                  id={'PhoneNumber'}
                  label={intl.formatMessage({
                    id: 'SignupForm.phoneNumberLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.phoneNumberPlaceholder',
                  })}
                  defaultCountry="DK"
                  validate={validators.composeValidators(
                    validators.validatePhoneNumber(
                      intl.formatMessage({ id: 'SignupForm.phoneNumberNotValidMessage' })
                    )
                  )}
                  
                />
              <br/>    

              <label><FormattedMessage id="SignupForm.userTypeLabel" /></label>
              <FieldRadioButton
                id="option-id1"
                name="userType"
                label={intl.formatMessage({id: 'SignupForm.rentLabel',})}
                value="customer"
               
              />
              <FieldRadioButton
                id="option-id2"
                name="userType"
                label={intl.formatMessage({id: 'SignupForm.renterLabel',})}
                value="supplier"
              />
              <FieldRadioButton
                id="option-id3"
                name="userType"
                label={intl.formatMessage({id: 'SignupForm.bothLabel',})}
                value="both"
              />
              
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />
            </div>

            <div className={css.bottomWrapper}>
              {termsAndConditions}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
            </>
             : null}   
               {openCompanySignup?
            <>
            <div>        
              <div className={css.sendInvoiceHEader}>
                <button className={css.backButton} onClick={handleBackLinkClick}>
                  <img src={BackArrow} alt="" width={30} />{' '} Back
                </button>
              </div>   
         
              
                <FieldTextInput                  
                  type="text"
                  id={formId ? `${formId}.companyName` : 'companyName'}
                  name="companyName"
                  autoComplete="given-name"
                  label = {companyNameLabel}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                
               <br/>              

                <FieldPhoneInput
                  className={css.phone}
                  name="phoneNumber"
                  id={'PhoneNumber'}
                  label={intl.formatMessage({
                    id: 'SignupForm.phoneNumberLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.phoneNumberPlaceholder',
                  })}
                  defaultCountry="DK"
                  validate={validators.composeValidators(
                    validators.validatePhoneNumber(
                      intl.formatMessage({ id: 'SignupForm.phoneNumberNotValidMessage' })
                    )
                  )}
                  
                />
              <br/>    
              <FieldTextInput
            type="number"
            id={formId ? `${formId}.cvr` : 'cvr'}
            name="cvr"
            autoComplete="cvr"
            label={intl.formatMessage({
              id: 'SignupForm.cvrLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.cvrPlaceholder',
            })}
            validate={cvrRequired}
          />
          <br></br>

              <label><FormattedMessage id="SignupForm.userTypeLabel" /></label>
              <FieldRadioButton
                id="option-id1"
                name="userType"
                label={intl.formatMessage({id: 'SignupForm.rentLabel',})}
                value="customer"
               
              />
              <FieldRadioButton
                id="option-id2"
                name="userType"
                label={intl.formatMessage({id: 'SignupForm.renterLabel',})}
                value="supplier"
              />
              <FieldRadioButton
                id="option-id3"
                name="userType"
                label={intl.formatMessage({id: 'SignupForm.bothLabel',})}
                value="both"
              />
              
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />
            </div>

            <div className={css.bottomWrapper}>
              {termsAndConditions}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
            </>
             : null}            
             <>
             {!openCompanySignup && !openPersonSignup && openSignUp?
             <>
              <label><FormattedMessage id = "SignupForm.areYouLabel" /></label>
              <FieldRadioButton
                id = "option-id1"
                name = "user"
                label = {intl.formatMessage({id: 'SignupForm.companyLabel',})}
                value = "company"
                onClick={(e) => handleUserTypeChange(e)}
              />
              <FieldRadioButton
                id = "option-id2"
                name = "user"
                label = {intl.formatMessage({id: 'SignupForm.privatePersonLabel',})}
                value = "person"
                onClick={(e) => handleUserTypeChange(e)}
              />
                <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}              
              />
              <br></br>
               <PrimaryButton type="submit" inProgress={submitInProgress} disabled={proceedSubmitDisabled} onClick = {handleProceedClick}>
                <FormattedMessage id="SignupForm.proceed" />
              </PrimaryButton>
              </>
              :null}
             </>
             
          </Form>
        );
      }}
    />
  ) 

};

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;

