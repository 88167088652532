// SignupFormStep2.js
import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import { Form, PrimaryButton, FieldTextInput } from '../../../components';

import css from './SignupForm.module.css';

const SignupFormStepComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
      } = fieldRenderProps;

      // CVR
      const cvrRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.cvrRequired',
        })
      );

      // MITID
      const mitidRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.mitidRequired',
        })
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <br/>
          <FieldTextInput
            type="text"
            id={formId ? `${formId}.cvr` : 'cvr'}
            name="cvr"
            autoComplete="cvr"
            label={intl.formatMessage({
              id: 'SignupForm.cvrLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.cvrPlaceholder',
            })}
            validate={cvrRequired}
          />
          <br></br>
          <FieldTextInput
            type="text"
            id={formId ? `${formId}.mitid` : 'mitid'}
            name="mitid"
            autoComplete="mitid"
            label={intl.formatMessage({
              id: 'SignupForm.mitidLabel',
            })}
            placeholder={intl.formatMessage({
              id: 'SignupForm.mitidPlaceholder',
            })}
            validate={mitidRequired}
          />
          <br></br>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.continue" />
            </PrimaryButton>
        
        </Form>
      );
    }}
  />
);

SignupFormStepComponent.defaultProps = { inProgress: false };

SignupFormStepComponent.propTypes = {
  inProgress: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupFormStep = compose(injectIntl)(SignupFormStepComponent);
SignupFormStep.displayName = 'SignupFormStep';

export default SignupFormStep;
